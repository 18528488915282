import React, { Component } from 'react'
import './Donate.css'
 
class DonateButton extends Component {
    static defaultProps = {
        items: []
    }

render() {
    const { items } = this.props
    return(
        <section class="donate-section">
        <div class="express-lane-links">
        <p class="info">Express Donate:</p>
        {!!items &&
            items.map((value) => ( 
                <a href={"https://secure.actblue.com/donate/cd24?express_lane=true&amount="+value+"&refcode=express_lane_"+value}><button className="Button donate-button">  ${value}  </button></a>
        ))}
        <a href="https://secure.actblue.com/donate/cd24?refcode=express_lane_other"><button className="Button donate-button">  Custom  </button></a>
        <p class="info">If you've saved your payment information with ActBlue Express, your donation will go through immediately.</p>
        </div>
        </section>

    )}}

export default DonateButton