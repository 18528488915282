import React from 'react'
import _kebabCase from 'lodash/kebabCase'
import './Accordion.css'

export default class Accordion extends React.Component {
  static defaultProps = {
    items: [],
    className: ''
  }

  state = {
    activeItem: null
  }

  handleClick = index => {
    this.setState({
      activeItem: this.state.activeItem === index ? null : index
    })
  }

  render() {
    const { items, className } = this.props
    return (
      <div className={`Accordion ${className}`}>
        {!!items &&
          items.map((item, index) => (
            <div
              className={`Accordion2--item`}
              key={`accordion2-item-${_kebabCase(item.title) + '-' + index}`}
            >
              <h2 className="flex">
                <span>{item.title}</span>
              </h2>
            </div>
          ))}
      </div>
    )
  }
}
