import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content.js'
import Layout from '../components/Layout.js'
import Accordion from '../components/Accordion'
import Accordion2 from '../components/Accordion2'
import Donate from '../components/Donate'

// Export Template for use in CMS preview
export const EndorsementsPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  section1,
  endorsequotes,
  endorsed,
  donatevalues
}) => (
  <main>
    <section className="section">
      <div className="container">
        <Content source={section1} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <Accordion items={endorsequotes} />
      </div>
      <div className="container">
        <Accordion2 items={endorsed} />
      </div>
    </section>
    {/* <Donate items={donatevalues} /> */}
  </main>
)

const EndorsementsPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <EndorsementsPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default EndorsementsPage

export const pageQuery = graphql`
  query EndorsementsPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        section1
        donatevalues
        endorsequotes {
          title
          description
        }
        endorsed {
          title
        }
      }
    }
  }
`
